.dtech-transformer-header-section {
  padding: 8px;
  border-bottom: 2px #2e374b solid;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span:first-child {
    color: white;
    font-weight: 1000;
    font-size: 20px;
  }

  .transformer-name-value {
    color: #76c993;
    font-weight: 1000;
    font-size: 20px;
  }
}

.transformer-header-inner-grid-container {
  display: flex;
}

.transformer-header-inner-grid {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.transformer-header-title-value {
  font-weight: 1000;
  color: #76c993;
  font-size: 12px;
}

.dtech-transformer-chart-first-section {
  padding: 10px;
  .dtech-transformer-chart-first-section-charts {
    // height: 35vh;
    margin-top: 11px;
    padding: 20px;
    width: 100%;
    background: #f2f4f7;
    display: block;
    canvas {
      padding: 10px;
      // background-color: #f2f4f7;
      // height: 25vh !important;
    }
    button {
      margin-top: 5px;
      height: 5vh;
      width: 6vw;
      font-size: 12px;
      border: none;
      border-radius: 10px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.dtech-transformer-chart-first-section-tables {
  padding:10px 0px;
  // height: 25%;
  .dtech-data-table-wrapper {
    table {
      // margin: 10px;
      border-radius: 30px;
      width: 100%;
    }
    th,
    td {
      padding: 15px;
    }
    th {
      font-size: 12px;
    }
  }
}

.dtech-transformer-chart-second-section {
  padding: 10px;
  align-items: flex-start;
  .dtech-transformer-chart-second-section-charts {
    height: 30vh;
    padding: 20px;
    background: #f2f4f7;
    display: block;
    canvas {
      padding: 10px;
      // background-color: #f2f4f7;
      // height: 20vh !important;
    }
    button {
      margin-top: 5px;
      height: 5vh;
      width: 6vw;
      font-size: 12px;
      border: none;
      border-radius: 10px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.dtech-transformer-chart-second-section-tables {
  height: 20%;
  border-radius: 30px;
  .dtech-data-table-wrapper {
    table {
      // margin: 10px;
      border-radius: 30px;
      width: 100%;
    }
    th,
    td {
      padding: 15px;
    }
    th {
      font-size: 12px;
    }
  }
}
.dtech-transformer-chart-second-section-tables {
  .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    box-shadow: none !important;
  }
}

#dark .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon ,.css-i4bv87-MuiSvgIcon-root {
  fill: #b9bdce !important;
}

 #light .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon, .css-i4bv87-MuiSvgIcon-root {
        fill:#3d3d3f !important

 }
