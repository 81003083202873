.dropdown-item {
  color: white;
  // background-color: blue!important;
}
.drp-down:hover {
  background-color: #d0d3df !important;
}
// .dropdown-item:focus, .dropdown-item:hover {
//     background-color: blue!important;
// }
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #d0d3df !important;
}
.List {
  border: 1px solid #d9dddd;
  // height: calc(100% - 120px) !important;
  // overflow-x: hidden !important;
  max-height: 100% !important;
}

.ListItemEven,
.ListItemOdd {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ListItemEven {
  background-color: #f8f8f0;
}

.sidebar-title-wrapper {
  background-color: none;
}
