.alarm-container {
  width: 300px;
  // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 5%;
  margin-top: 10%;
}
.table-container {
  border: "1px solid #d0d3df";
  color: "red";
  background-color: #24272d !important;
}
#dark .table-container {
  border: "1px solid #d0d3df";
  color: "red";
  background-color: #24272d !important;
}
#light .table-container {
  border: "1px solid #d0d3df";
  color: "red";
  background-color: #f2f4f7 !important;
}
#dark .table-header {
  // border: "1px solid #d0d3df";
  font-weight: bold;
  color: #b9bdce !important;
  background-color: #24272d !important;
}
#light .table-header {
  // border: "1px solid #d0d3df";
  font-weight: bold;
  color: #3d3d3f !important;
  background-color: #f2f4f7 !important;
}
#dark .table-row {
  // border: "1px solid #d0d3df";
  color: #b9bdce !important;
  text-align: left !important;
}
#light .table-row {
  // border: "1px solid #d0d3df";
  color: #3d3d3f !important;
  text-align: left !important;
}

.css-dsuxgy-MuiTableCell-root {
  color: rgb(233, 62, 62) !important;
}
.css-dv0mq-MuiTableCell-root {
  color: rgb(233, 62, 62) !important;
}
.css-1howxi1-MuiTableCell-root {
  color: black !important;
}
.css-6qfsqn-MuiTableCell-root {
  color: black !important;
}
.css-1qk55va-MuiTableRow-root {
  border: none !important;
}
.css-dsuxgy-MuiTableCell-root {
  border-bottom: none !important;
}
.css-6qfsqn-MuiTableCell-root {
  border-bottom: none !important;
}
.css-dv0mq-MuiTableCell-root {
  border-bottom: none !important;
}
.css-1howxi1-MuiTableCell-root {
  border-bottom: none !important;
}
.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  background-color: "white" !important;
}
.map-alarm-chart-container .map-alarm-container .map-container .custom-alarm {
  width: 100% !important;
}
// .css-11xur9t-MuiPaper-root-MuiTableContainer-root{
//     width: 60% !important;
// }

#dark .table-responsive {
  overflow-x: auto;
  height: 100%;
  background-color: #24272d;
  border-radius: 5px;
}
#light .table-responsive {
  overflow-x: auto;
  height: 100%;
  background-color: #f2f4f7;
  border-radius: 5px;
}
