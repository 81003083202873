.dtech-customer-header-section {
  padding: 8px;
  border-bottom: 2px #2e374b solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span:first-child {
    color: white;
    font-weight: 1000;
    font-size: 20px;
  }

  .customer-name-value {
    color: #76c993;
    font-weight: 1000;
    font-size: 20px;
  }
}

.customer-header-inner-grid-container {
  display: flex;
}

.customer-header-inner-grid {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.customer-header-title-value {
  font-weight: 1000;
  color: #76c993;
  font-size: 12px;
}

.dtech-day-load-curve-data-section {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
}

.dtech-day-load-curve-data-charts {
  height: 40vh !important;
  padding: 10px;
  width: 48%;
  margin-top: 11px;
  // background: #f2f4f7;
  canvas {
    padding: 10px;
    // background-color: #f2f4f7;
    height: 38vh !important;
  }
  button {
    margin-top: 5px;
    height: 5vh;
    width: 6vw;
    font-size: 12px;
    border: none;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.dtech-other-load-data-section {
  display: block;
  // padding: 10px;
  // margin: 5px;
  .dtech-other-load-data-charts {
    // height: 36vh;
    // margin: 5px;
    padding: 10px;
    width: 49%;
    border-radius: 5px;
    // background: #f2f4f7;
    canvas {
      padding: 10px;
      // background-color: #f2f4f7;
      height: 45vh !important;
    }
    button {
      margin-top: 5px;
      height: 5vh;
      width: 6vw;
      font-size: 12px;
      border: none;
      border-radius: 10px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
#dark .dtech-other-load-data-section {
// background: #24272D;
}
#light .dtech-other-load-data-section{
  // background: #f2f4f7;
}

.grafana-toggle-btn {
  width: 40px;
  margin-left: 10px;
  cursor: pointer;
}

#dark .dtech-alarm-table-container {
  .table-responsive {
    overflow-x: auto;
    align-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    th,
    td {
      font-size: 10px;
    }
  }
}
.dtech-data-table-wrapper {
  table {
    margin: 10px;
    border-radius: 30px;
    width: 100%;
  }
  th,
  td {
    padding: 15px;
  }
  th {
    font-size: 12px;
  }
}

.dtech-other-load-data-table-section-wrapper {
  display: grid;
  justify-content: space-between;
}
.dtech-other-load-data-table-section {
  margin-bottom: 30px;
}

