.main-container {
  //  width: 25%;
  //  margin-top: 50px;
  //  margin-left: 50px;
  //  height: 100%;

  
}
.sidebar {
  //  background-color: #333;
  color: white;
  //  padding: 20px;
  //  width: 200px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.button-container {
  padding-bottom: 20px;
}
.dropdown-toggle::after {
  display: none !important;
 }
 
 .dropdown-item:focus {
  background-color: none !important; 
}

.dropdown-item:focus {
  background-color: none !important;
}
.dropdown-item:hover {
  background-color: none !important;
}
