.home-page {
  display: flex;
  flex-direction: column;
  // background-color: #002137;
  height: 100%;
}

.map-component {
  flex: 1;
  height: 100vh;
  margin-bottom: 20px;
  border-radius: 10;
}
.alarm-chart-container {
  display: flex;
  flex-direction: row;
}
.content-container {
  display: flex;
  flex-direction: column;
}
.home-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.alarm-component {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 5%;
  margin-top: 2%;
}
.tab-end {
  align-items: flex-end;
  justify-content: end;
  text-align: right;
  padding: "10px";
  margin-bottom: "10px";
  margin: "0px";
}
.MuiBox-root {
  width: 98%;
}
.offcanvas {
  background-color: #24272d !important;
  color: white;
}
.side-canvas{
  background-color: #24272d !important;
  color: white;
  height: 100%;
}
.offcanvas-body {
  color: white !important;
}

.dtech-main-app {
  display: flex;
  height: calc(100% - 60px);
  flex-direction: column;

  .sidebar {
    width: 300px; /* Set sidebar width */
    background-color: #f2f4f7;
    transition: width 0.3s ease;
    border-radius: 5px;
    // overflow-y: auto;
    height: 100%;
    // calc(100% - 58px);
  }

  .sidebar.collapsed {
    width: 0;
  }

  .sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }

  .main-content {
    flex-grow: 1;
    padding: 10px;
  }

  .item {
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }

  .toggle-button {
    background: none;
    border: none;
    cursor: pointer;
  }
  .indicator {
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-left: 10px; 
    border-radius: 50%; 
  }
  /* For tablet and above */
  @media only screen and (min-width: 768px) {
    .sidebar {
      width: 300px;
    }
    .sidebar.collapsed {
      width: 0;
    }
  }

  /* For desktop */
  @media only screen and (min-width: 992px) {
    .sidebar {
      width: 300px;
    }
    .sidebar.collapsed {
      width: 0;
    }
  }

  /* For large desktop */
  @media only screen and (min-width: 1200px) {
    .sidebar {
      width: 300px;
    }
    .sidebar.collapsed {
      width: 0;
    }
  }

  .sidebar-content {
    width: 100%;
    // height: 99%;
  }
}

html,
body,
#root,
.App {
  height: 100%;
}

#dark {
  .sidebar {
    background: #24272D !important;
  }
}
