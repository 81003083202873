.seqrops-frame-conworx {
  height: 76vh;
}

.dashboard-close-btn {
  padding: 10px;
  background: #f4f0f0;
  border-radius: 8px;
  height: 30px;
  width: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: 30px;
  margin-top: 4px;
  cursor: pointer;
  border: 1px solid #d6d6d7;
  margin-left: 10px;
  color: #3d3d3f;
}
