.map-alarm-chart-container {
  display: flex;
  flex-direction: column;
}

.map-alarm-container {
  display: flex;
  flex: 1;
}

.map-container {
  flex: 2;
  width: 100%;
}

.chart-container {
  flex: 1;
  margin-right: 10px;
}

.alarm-component {
  width: 100%;
  align-self: flex-end;
}
.leaflet-marker-icon,
.leaflet-div-icon {
  background-color: transparent !important;
  border: transparent !important;
}
#dark .dtech-map-data-section {
  padding:5px 20px;
  // height: 320px;
  background-color: #24272D;
  border-radius: 10px;
  margin-top: 10px;
}
#light .dtech-map-data-section {
  // height: 320px;
  background-color: #f2f4f7;
  border-radius: 10px;
  margin-top: 10px;
  padding:5px 20px;

}
.dtech-map-footer-chart-data-section {
  display: flex;
}
.flex-direction-row {
  flex-direction: row;
}
.flex-direction-column {
  flex-direction: column;
}
