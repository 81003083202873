.dtech-subsstation-header-section {
  padding: 8px;
  border-bottom: 2px #2e374b solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span:first-child {
    color: white;
    font-weight: 1000;
    font-size: 18px;
  }

  .substation-name-value {
    color: #76c993;
    font-weight: 1000;
    font-size: 20px;
  }
}

.substation-header-inner-grid {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}
.substation-header-title-value {
  font-weight: 1000;
  color: #76c993;
  font-size: 12px;
}

.substation-header-inner-grid-container {
  display: flex;
}
.dtech-subsstation-model-property-list-section {
  margin-top: 36px;
  display: flex;
  overflow-x: auto;
}

.dtech-subsstation-model-property-list-item {
  background: #f2f4f7;
  font-size: 12px;
  padding: 8px;
  flex: 1;
  min-width: 120px;
  height: 8vh;
  color: white;
  display: grid;
  margin-right: 8px;
  overflow: hidden;
  border-radius: 12px;
}

.dtech-subsstation-model-property-list-item-value,
.dtech-subsstation-model-property-list-item-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dtech-subsstation-model-property-list-item-value {
  color: #76c993;
  font-weight: 1000;
  font-size: 16px;
}
.dtech-subsstation-model-peak-time-property-list-section {
  padding: 8px;
  display: flex;
  flex-direction: column;
  // overflow-x: auto;
  // overflow-y: hidden;
  background-color: #f2f4f7;
  border-radius: 12px;
  max-height: 16vh;
  margin-top: 11px;
}

.dtech-subsstation-model-peak-time-property-list-section h6 {
  color: white;
  display: flex;
  padding: 10px;
  margin-bottom: 6px;
}

.dtech-subsstation-model-peak-time-property-list {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

.dtech-subsstation-model-peak-time-property-list-item {
  font-size: 12px;
  background: #f9fdff;
  padding: 8px;
  flex: 0 0 auto;
  display: grid;
  min-width: 120px;
  height: 8vh;
  color: white;
  margin-right: 9px;
  margin-bottom: 4px;
  overflow: hidden;
  border-radius: 12px;
}

.dtech-subsstation-model-peak-time-property-list-item-value,
.dtech-subsstation-model-peak-time-property-list-item-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #b9bdce;
}

.dtech-subsstation-model-peak-time-property-list-item-value {
  color: #5357ed;
  font-weight: 1000;
  font-size: 16px;
}

.dtech-substation-header-chart-data-section {
  height: 30vh;
  margin-top: 11px;
  padding: 20px;
  background: #f2f4f7;
  display: block;
  canvas {
    // background-color: #f2f4f7;
    height: 27vh !important;
  }
  button {
    margin-top: 5px;
    height: 5vh;
    width: 6vw;
    font-size: 12px;
    border: none;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.dtech-substation-footer-chart-data-section {
  // margin-top: 40px;
  padding: 5px 10px;
}
#dark .dtech-substation-footer-chart-data-section .middle-chart-div {
  background: #24272d;
  border-radius: 5px;
}
#light .dtech-substation-footer-chart-data-section .middle-chart-div {
  background: #f2f4f7;
  border-radius: 5px;

}

.dtech-substation-voltage-data-section {
  height: 100%;
  margin-top: 11px;
  padding: 20px;
  // background: #f2f4f7;
  display: block;
  border-radius: 5px;
  canvas {
    // background-color: #f2f4f7;
    // height: 27vh !important;
  }
  button {
    margin-top: 5px;
    height: 5vh;
    width: 6vw;
    font-size: 12px;
    border: none;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
