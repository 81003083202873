.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (min-width: 2000px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1780px !important;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #B9BDCE;
}

.Toastify__toast-container {
  min-width: 400px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* body {
  background: #f9f9f9 !important;
} */

#light {
  background: #F9FDFF !important;

  .dtech-subsstation-model-property-list-item-name,
  .dtech-subsstation-model-peak-time-property-list-item-name,
  .dtech-subsstation-heading,
  .dtech-transformer-heading,
  .dtech-customer-heading,
  .substation-header-title,
  .customer-header-title,
  .transformer-header-title {
    color: #3d3d3f !important;
  }

  .dtech-subsstation-model-peak-time-property-list-section h5 {
    color: #3d3d3f !important;
  }

  .dtech-subsstation-header-section,
  .dtech-customer-header-section,
  .dtech-transformer-header-section {
    border-bottom: 2px #d5d7da solid;
  }

  .dtech-subsstation-model-peak-time-property-list-item,
  .dtech-subsstation-model-property-list-item {
    border: 1px #d0d3df solid;
  }

  .dtech-subsstation-model-peak-time-property-list-section h6 {
    color: #3d3d3f !important;
  }

  .grid-element-section-header {
    background: #f2f4f7;
    color: #3d3d3f !important;
    font-size: x-small;
    padding: 6px;
    border: 1px #d0d3df solid;
    border-radius: 8;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    font-size: 16px;
  }

  .top-bar {
    background: #f2f4f7;
    /* border: 1px #d0d3df solid; */
    color: #59595a !important;
    text-align: left;
    font-size: x-small;
    border-radius: 5;
    /* display: flex;
    flex-direction: row; */

  }

  .MuiTab-root {
    color: #3d3d3f !important;
  }

  .css-1howxi1-MuiTableCell-root {
    color: rgb(243, 72, 72) !important;
  }

  .css-6qfsqn-MuiTableCell-root {
    color: rgb(243, 72, 72) !important;
  }

  .css-13njpue {
    color: rgb(243, 72, 72) !important;
  }

  .css-12pjbv5 {
    color: rgb(243, 72, 72) !important;
  }

  .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    background-color: #f2f4f7 !important;


  }

  .css-1tyay50-MuiTable-root {
    background-color: #f2f4f7 !important;
  }

  .css-dv0mq-MuiTableCell-root {
    color: black !important;
  }

  .css-dsuxgy-MuiTableCell-root {
    color: black !important;
  }

  .css-1o6fzn1 {
    color: black !important;
  }

  .offcanvas {
    background-color: #f2f4f7 !important;

  }

  .side-canvas {
    background-color: #f2f4f7 !important;
    color: black;
    height: 100%;
  }

  .offcanvas-body {
    color: #000 !important;
  }

  .dropdown-menu {
    padding: 11px 55px;
    padding: 39px;
    background: #f2f4f7 !important;
    color: #3d3d3f !important;
    border: 1px #d0d3df solid !important;

  }

  .dropdown-header {
    border: none !important;
    background: #f2f4f7 !important;
    color: #3d3d3f !important;
  }

  .form-control {
    border: 1px #d0d3df solid !important;
    background: #d0d3df !important;
    color: #3d3d3f !important;
  }

  .dropdown-item {
    color: #3d3d3f !important;
  }

  .sidebar-title-wrapper-active {
    background: #b3b3b3 !important;

    span {
      color: white m !important;
    }
  }

  .segment {
    display: flex;
    background: #f2f4f7 !important;
    padding: 10px;
    border: 1px #d0d3df solid !important;
    color: #3d3d3f !important;

  }

  .seqrops-inner-container {
    height: 87vh;
  }

  .left-section-selection-wrapper {
    background: #f2f4f7 !important;
    height: 100%;
  }

  .map-wrapper {
    /* background: #f2f4f7 !important; */
    /* height: 100%; */
  }

  .grid-element-section {
    background: #f2f4f7 !important;
    color: #3d3d3f !important;
    padding: 10x !important;
  }

  .grid-element-section-wrapper {
    background: #F9FDFF !important;
  }

  .seqrops-query-builder-wrapper,
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
  .css-1x5jdmq {
    background: #F9FDFF !important;
    color: #3d3d3f !important;
  }

  .seqrops-query-builder-list {
    border-bottom: 1px solid #d0d3df;

  }

  .seqrops-query-section {
    background: #f2f4f7 !important;
    border: 1px #d0d3df solid !important;
    color: #3d3d3f !important;
  }

  .seqrops-query-section-text {

    color: #3d3d3f !important;
  }

  .query-span {
    background-color: #FFFFFF;

    img {
      cursor: pointer !important;
    }
  }

  .seqrops-query-btn {
    background: #2263c4 !important;
  }

  .seqrops-query-btn .dropdown-menu .show {
    background: #2263c4 !important;
  }

  .dtech-day-load-curve-data-div {
    background-color: #f2f4f7;
    height: 100%;
    border-radius: 5px;

    .chart-span {
      border-radius: 5px;
    }
  }

  .dtech-transformer-chart-second-section-charts,
  .dtech-transformer-chart-first-section-charts {
    border-radius: 5px;

    canvas {
      background: #f2f4f7 !important;
      color: #b9bdce !important;
      /* height: 20vh !important; */
    }
  }

  .dtech-instance-table table {
    color: #3d3d3f !important;
  }

  .dtech-instance-table table tr:nth-of-type(2n) {
    background: #f2f4f7 !important;
  }

  .dtech-instance-table table tr:hover {
    background: #f2f4f7 !important;
  }

  .dtech-alarm-list-table-row {
    color: rgb(245, 67, 67);
  }

  .MuiToolbar-root,
  .MuiButtonBase-root {
    color: #3d3d3f !important;
    align-items: baseline;
  }

  .no-data-available-text {
    font-size: xx-large;
    color: #3d3d3f;
  }

  .ListItem {
    border-radius: 0px 0px 7px 7px;
    border: 1px solid #b6c4e52e
  }

}

/* 192734 */

#dark {

  background: #111217 !important;

  .seqrops-dashboard-tile-wrapper {
    background: #181B1F !important;
    border-color: #24272D;
    box-shadow: none;
  }

  .seqrops-options-text {
    color: #B9BDCE !important;
  }


  .seqrops-dashboard-tile-wrapper span {
    color: #B9BDCE !important;
  }

  .seqrops-navbar-wrapper {
    background: #24272D !important;
    color: white !important;

  }

  .p-overlaypanel-content {
    background: #24272D !important;
    color: white !important;

  }

  .dtech-subsstation-model-peak-time-property-list-section h6 {
    color: #B9BDCE !important;
  }

  .logout-section span {
    color: white !important;

  }

  .target-app-container {
    background: #24272D !important;
    color: white !important;
    border-color: #24272D;
  }

  .seqrops-username-box {
    .seqrops-username-text {
      background-color: #081f6b;
      color: #56bbff;
    }

    .seqrops-username {
      color: #B9BDCE;
    }
  }

  .dark-navbar {
    background: #24272D !important;
  }

  .sidebar-title-wrapper-active {
    background: rgb(46, 55, 75) !important;
  }

  .dashboard-close-btn {
    background: rgb(46, 55, 75);
    color: #B9BDCE !important;
    border: 1px solid #595858;
  }


  .seqrops-options-text {
    color: #B9BDCE !important;
  }

  .p-dialog-header {
    background-color: #24272d !important;
    color: #b9bdce !important;
  }

  .p-dialog-content {
    background-color: #24272d !important;
    color: #b9bdce !important;
  }

  .p-dialog-footer {
    background-color: #24272d !important;
    color: #b9bdce !important;
  }

  .dtech-subsstation-model-peak-time-property-list-item {
    background: #24272d;
    border: 1px #494950 solid !important;
  }

  .css-13xy2my {
    background-color: #24272d !important;
  }

  .btn-close {
    background-color: white;
  }

  .css-1thfh4x {
    color: white !important;
  }

  .css-1o6fzn1 {
    color: white !important;
  }

  .css-dsuxgy-MuiTableCell-root {
    color: white !important;
  }

  .css-13njpue {
    color: rgb(243, 72, 72) !important;
  }

  .css-12pjbv5 {
    color: rgb(243, 72, 72) !important;
  }

  .offcanvas {
    background-color: #24272d !important;
  }

  .dtech-subsstation-model-peak-time-property-list-section {
    background-color: #2e374b;
  }

  .css-dv0mq-MuiTableCell-root {
    color: white !important;
  }

  .dtech-subsstation-model-property-list-item {
    background: #24272d;
    border: 1px #494950 solid !important;
  }

  .dtech-subsstation-model-property-list-item-name,
  .dtech-subsstation-model-peak-time-property-list-item-name,
  .dtech-subsstation-heading,
  .dtech-customer-heading,
  .dtech-transformer-heading,
  .substation-header-title,
  .customer-header-title,
  .transformer-header-title {
    color: #B9BDCE !important;
  }

  .top-bar {
    border: 1px #494950 solid !important;
    background: #24272d;
    text-align: left;
    font-size: x-small;
    border-radius: 5;
    /* display: flex;
    flex-direction: row; */

  }

  .MuiTab-root {
    color: #b9bdce !important;

  }

  .css-1howxi1-MuiTableCell-root {
    color: rgb(243, 72, 72) !important;
  }

  .css-6qfsqn-MuiTableCell-root {
    color: rgb(243, 72, 72) !important
  }

  .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    background-color: #24272D;

  }

  .css-1tyay50-MuiTable-root {
    background-color: #24272d !important;
  }

  .grid-element-section-header {
    background: #24272d;
    color: #b9bdce;
    display: flex;
    padding: 6px;
    border-radius: 8;
    border: 1px #494950 solid !important;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    font-size: 16px;
  }

  .dropdown-menu {
    background: #24272d !important;
    color: #b9bdce !important;
    padding: 39px;
    width: 14.5vw;
    border: 1px #494950 solid !important;

  }

  .dropdown-header {
    border: none !important;
    background: #24272d !important;
    color: #b9bdce !important;
  }

  .form-control {
    border: 1px #494950 solid !important;
    background: #2e374b !important;
    color: #b9bdce !important;
  }

  .dropdown-item {
    color: #b9bdce !important;
  }

  .dropdown-item:hover {
    background: #2e374b !important;
  }

  .segment {
    display: flex;
    background: #24272d !important;
    padding: 10px;
    border: 1px #494950 solid !important;
    color: #b9bdce !important;

  }

  .grid-element-section {
    background: #24272d !important;
    padding: 10px !important;
  }

  .left-section-selection-wrapper {
    background: #24272D !important;
    height: 100%;
  }

  .map-wrapper {
    /* background: #24272D !important; */
    /* height: 100%; */
  }

  .seqrops-inner-container {
    height: 87vh;
  }


  .seqrops-query-builder-wrapper,
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
  .css-1x5jdmq {
    background: #24272d !important;
    color: #FFFFFF !important;
  }

  .seqrops-query-builder-list {
    border-bottom: 1px solid #494950;

  }

  .seqrops-query-section {
    background: #24272d !important;
    border: 1px #494950 solid !important;
    color: #b9bdce !important;
  }

  .seqrops-query-section-text {

    color: #b9bdce !important;
  }

  .query-span {
    background-color: #00111c;

    img {
      cursor: pointer !important;
    }
  }

  .seqrops-query-btn {
    background: #24272d !important;
  }

  .dtech-data-table-wrapper {
    background-color: #111217;

    table {
      background-color: #24272d;
      color: #b9bdce !important;
    }
  }

  .dtech-day-load-curve-data-charts,
  .dtech-other-load-data-charts {

    canvas {
      background-color: #24272d;
      color: #b9bdce !important;
    }

    button {
      background-color: #24272d;
      color: #b9bdce !important;
      border: none;
    }

    button:hover {
      background-color: #24272d;
      color: #b9bdce !important;
      border: none;
      background-color: #2f2f35 !important;
    }
  }

  .dtech-day-load-curve-data-div {
    background-color: #24272d;
    height: 100%;
    border-radius: 5px;

    .chart-span {
      border-radius: 5px;
    }
  }

  .dtech-substation-header-chart-data-section,
  .dtech-substation-voltage-data-section {
    /* background-color: #24272d; */
    color: #b9bdce !important;
    border-radius: 5px;
    height: 100%;

    canvas {
      background-color: #24272d;
      color: #b9bdce !important;
    }

    button {
      background-color: #2f2f35 !important;
      color: #b9bdce !important;
      border: none;
    }

    button:hover {
      color: #b9bdce !important;
      border: none;
      background-color: #2f2f35 !important;
    }
  }

  .dtech-transformer-chart-first-section-charts {
    border-radius: 5px;
    background-color: #24272d;

    canvas {
      background-color: #24272d;
      color: #b9bdce !important;
    }

    button {
      background-color: #2f2f35;
      color: #b9bdce !important;
      border: none;
    }

    button:hover {
      background-color: #24272d;
      color: #b9bdce !important;
      border: none;
      background-color: #2f2f35 !important;
    }
  }

  .ListItem {
    border-radius: 0px 0px 7px 7px;
    border: 1px solid #2a3244
  }

  .dtech-transformer-chart-second-section-charts {
    background-color: #24272d;

    canvas {
      background-color: #24272d;
      color: #b9bdce !important;
      /* height: 20vh !important; */
    }

    button {
      background-color: #2f2f35;
      color: #b9bdce !important;
      border: none;
    }

    button:hover {
      color: #b9bdce !important;
      border: none;
      background-color: #24272d !important;
    }
  }

  .dtech-alarm-list-table-row {
    color: rgb(233, 91, 91);
  }

  .MuiToolbar-root,
  .MuiButtonBase-root {
    color: #b9bdce !important;
    align-items: baseline;
  }
}

.no-data-available-text {
  font-size: xx-large;
  color: #b9bdce;
}


.seqrops-dashboard-wrapper {
  /* height: calc(100vh - 34px); */
  height: 100vh
}

/*loader */

.grid {
  display: flex;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
}

.loader::before {
  content: '';
  background: rgba(242, 235, 235, 0.5);
  position: fixed;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 0;
  height: calc(100vh - 0px);
  z-index: 0;
}

.loader {
  width: 50vw;
  border: 1px solid mistyrose;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

#ld1 {
  position: relative;
  transform: rotate(45deg);
}

#ld1 div {
  height: 20px;
  width: 20px;
  /* background: #FE4A49; */
  background: #0072BB;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#ld1 div:nth-child(1) {
  animation: ld1_div1 1s ease-in-out infinite;
}

#ld1 div:nth-child(2) {
  animation: ld1_div2 1s ease-in-out infinite;
}

#ld1 div:nth-child(3) {
  animation: ld1_div3 1s ease-in-out infinite;
}

@keyframes ld1_div1 {
  0% {
    top: 52.5px;
    /* background: #FE4A49; */
    background: #0072BB;
  }

  50% {
    top: -52.5px;
    /* background: #59CD90; */
    background: #0072BB;
  }

  100% {
    top: 52.5px;
    /* background: #009FB7; */
    background: #0072BB;
  }
}

@keyframes ld1_div2 {
  0% {
    right: 52.5px;
    /* background: #FE4A49; */
    background: #0072BB;
  }

  50% {
    right: -52.5px;
    /* background: #FED766; */
    background: #0072BB;
  }

  100% {
    right: 52.5px;
    /* background: #59CD90; */
    background: #0072BB;
  }
}

@keyframes ld1_div3 {
  0% {
    left: 52.5px;
    /* background: #FE4A49; */
    background: #0072BB;
  }

  50% {
    left: -52.5px;
    /* background: #D91E36; */
    background: #0072BB;
  }

  100% {
    left: 52.5px;
    /* background: #FE4A49; */
    background: #0072BB;
  }
}

#ld2 {
  display: flex;
  flex-direction: row;
}

#ld2 div {
  height: 20px;
  width: 5px;
  /* background: #FE4A49; */
  background: #0072BB;
  margin: 3px;
  border-radius: 25px;
}

#ld2 div:nth-child(1) {
  animation: ld2 1s ease-in-out infinite 0s;
}

#ld2 div:nth-child(2) {
  animation: ld2 1s ease-in-out infinite 0.1s;
}

#ld2 div:nth-child(3) {
  animation: ld2 1s ease-in-out infinite 0.2s;
}

#ld2 div:nth-child(4) {
  animation: ld2 1s ease-in-out infinite 0.3s;
}

#ld2 div:nth-child(5) {
  animation: ld2 1s ease-in-out infinite 0.4s;
}

#ld2 div:nth-child(6) {
  animation: ld2 1s ease-in-out infinite 0.5s;
}

#ld2 div:nth-child(7) {
  animation: ld2 1s ease-in-out infinite 0.6s;
}

@keyframes ld2 {
  0% {
    transform: scaleY(1);
    /* background: #FED766; */
    background: #0072BB;
  }

  25% {
    /* background: #009FB7; */
    background: #0072BB;
  }

  50% {
    transform: scaleY(2);
    /* background: #59CD90; */
    background: #0072BB;
  }

  75% {
    /* background: #FE4A49; */
    background: #0072BB;
  }

  100% {
    transform: scaleY(1);
    /* background: #D91E36; */
    background: #0072BB;
  }
}

#ld3 {
  position: relative;
  animation: outercontainer 4s linear infinite;
}

#ld3 div {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#ld3 div:nth-child(1) {
  top: 20px;
  /* background: #59CD90; */
  background: #0072BB;
  animation: ld3_div1 2s linear infinite;
}

#ld3 div:nth-child(2) {
  top: -20px;
  /* background: #D91E36; */
  background: #0072BB;
  animation: ld3_div2 2s linear infinite;
}

#ld3 div:nth-child(3) {
  left: 20px;
  /* background: #F39237; */
  background: #0072BB;
  animation: ld3_div4 2s linear infinite;
}

#ld3 div:nth-child(4) {
  left: -20px;
  /* background: #0072BB; */
  background: #0072BB;
  animation: ld3_div3 2s linear infinite;
}

@keyframes outercontainer {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes ld3_div1 {
  0% {
    top: 20px;
  }

  25% {
    top: 0;
  }

  50% {
    top: 20px;
  }

  75% {
    top: 0;
  }

  100% {
    top: 20px;
  }
}

@keyframes ld3_div2 {
  0% {
    top: -20px;
  }

  25% {
    top: 0;
  }

  50% {
    top: -20px;
  }

  75% {
    top: 0;
  }

  100% {
    top: -20px;
  }
}

@keyframes ld3_div3 {
  0% {
    left: -20px;
  }

  25% {
    left: 0;
  }

  50% {
    left: -20px;
  }

  75% {
    left: 0;
  }

  100% {
    left: -20px;
  }
}

@keyframes ld3_div4 {
  0% {
    left: 20px;
  }

  25% {
    left: 0;
  }

  50% {
    left: 20px;
  }

  75% {
    left: 0;
  }

  100% {
    left: 20px;
  }
}

#ld4 {
  position: relative;
  display: flex;
  width: 25%;
  justify-content: space-between;
}

#ld4 div {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  /* background: #D91E36; */
  background: #0072BB;
}

#ld4 div:nth-child(1) {
  animation: ld4 3s linear infinite 0s;
}

#ld4 div:nth-child(2) {
  animation: ld4 3s linear infinite 0.15s;
}

#ld4 div:nth-child(3) {
  animation: ld4 3s linear infinite 0.3s;
}

#ld4 div:nth-child(4) {
  animation: ld4 3s linear infinite 0.45s;
}

@keyframes ld4 {
  0% {
    opacity: 0;
    transform: scale(0.3);
    /* background: #59CD90; */
    background: #0072BB;
  }

  25% {
    opacity: 1;
    transform: scale(1.8);
    /* background: #0072BB; */
    background: #0072BB;
  }

  50% {
    opacity: 0;
    transform: scale(0.3);
    /* background: #FE4A49; */
    background: #0072BB;
  }

  75% {
    opacity: 1;
    transform: scale(1.8);
    /* background: #FED766; */
    background: #0072BB;
  }

  100% {
    opacity: 0;
  }
}

.loader-row {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  bottom: 0;
  margin: 0 auto;
}

@media (max-width: 1200px) {

  .customer-name-value,
  .dtech-customer-heading,
  .customer-header-title,
  .customer-header-title-value,
  .dtech-transformer-heading,
  .transformer-name-value,
  .transformer-header-title,
  .transformer-header-title-value,
  .dtech-subsstation-heading,
  .substation-name-value,
  .substation-header-title,
  .substation-header-title-value {
    font-size: 11px !important;
  }

  .grafana-toggle-btn {
    margin-left: 3px !important;
    width: 25px !important;
  }

}