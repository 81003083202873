.left-section-selection-wrapper ::-webkit-scrollbar-track {
    // background: #1304e0;
  }
  ::-webkit-scrollbar {
    width: 10px;
    height: 0px;
  }
  .query-builder ::-webkit-scrollbar {
    // width: 10px;
    height: 0px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px #02457a; 
    border-radius: 10px;
    width: 0px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #02457a; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #02457a; 
  }
  .seqrops-query-section {
    .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
      color: unset;
      border: none;
    }
  }
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .seqrops-query-section {
    border: none;
    .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: none !important;
    }
    .css-1v4ccyo.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: none !important;
    }
  }
  
  