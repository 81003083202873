.dtech-instance-table {
  padding: 12px;
}

.dtech-instance-table table tr:nth-of-type(2n) {
  background: #24272d;
}

.dtech-instance-table table tr:hover {
  background: #24272d;
}

.dtech-instance-table table {
  padding: 10px;
  text-align: left;
  color: #b9bdce;
}

.dtech-alarm-list-table-row {
  padding: 10px;
}
.css-levciy-MuiTablePagination-displayedRows {
  display: none !important;
}
